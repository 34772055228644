import React, { Component } from "react";
import NadpisStranky from "./../common/nadpis";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import FloatingLabelInput from "floating-label-react";
import auth from "../../services/authService";
import http from "../../services/httpService";
import config from "../../config.json";
import DodaciAdresa from "./DodaciAdresa";
import Osoba from "./Osoba";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import LoadMusic from "./../common/LoadMusic";
import MojePrihlasky from "./MojePrihlasky";
import ZmenaHesla from "./ZmenaHesla";
import Bonus from "../bonus/Bonus";
import {NavLink} from "react-router-dom";

class Profil extends Component {
  state = {
    firma: {},
    aktualniFirma: {},
    dorucovaciAdresyLoaded: false,
    dorucovaciAdresy: [],
    dokumentyLoaded: false,
    dokumenty: [],
    osobyLoaded: false,
    osoby: [],
    klientiLoaded: false,
    klienti: [],
  };

  async componentDidMount() {
    const aktualniFirma = auth.aktualniFirma();
    const firma = await http.post(config.apiEndpoint + "firma/detail.php", {
      firma_id: aktualniFirma.firma_id,
    });
    this.setState({ aktualniFirma, firma: firma.data });
  }

  handleChange = () => {
    //alert("change itemx");
  };

  obecneInformace = () => {
    if (!this.state.firma.firma_id) {
      return <LoadMusic />;
    }
    const lang = auth.getLang();
    return (
      <div className="padding-top-25">
        <h3 className="light upper underline center spacing-5">
          {lang.kontaktni_udaje}
        </h3>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-12">
            <FloatingLabelInput
              id="nazev"
              placeholder={lang.nazev_spolecnosti}
              name="nazev"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.nazev}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="ulice"
              placeholder={lang.ulice}
              name="ulice"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.ulice}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="mesto"
              placeholder={lang.mesto}
              name="mesto"
              value={this.state.firma.mesto}
              type="text"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="psc"
              placeholder={lang.psc}
              name="psc"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.psc}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="zeme"
              placeholder={lang.zeme}
              name="zeme"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.zeme}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="ico"
              placeholder={lang.ico}
              name="ico"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.ico}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="dic"
              placeholder={lang.dic}
              name="dic"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.dic}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="email"
              placeholder={lang.email}
              name="email"
              type="email"
              onChange={this.handleChange}
              value={this.state.firma.email}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="www"
              placeholder={lang.www}
              name="www"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.www}
            />
          </div>
        </div>
        <h4 className="light center upper padding-20">
          {lang.adresa_faktury}
          <small>{lang.pokud_se_lisi}</small>
        </h4>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-12">
            <FloatingLabelInput
              id="fakturace_email"
              placeholder={lang.email}
              name="fakturace_email"
              type="email"
              onChange={this.handleChange}
              value={this.state.firma.fakturace_email}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="fakturace_ulice"
              placeholder={lang.ulice}
              name="fakturace_ulice"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.fakturace_ulice}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="fakturace_mesto"
              placeholder={lang.mesto}
              name="fakturace_mesto"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.fakturace_mesto}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="fakturace_psc"
              placeholder={lang.psc}
              name="fakturace_psc"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.fakturace_psc}
            />
          </div>
          <div className="col-md-3">
            <FloatingLabelInput
              id="fakturace_zeme"
              placeholder={lang.zeme}
              name="fakturace_zeme"
              type="text"
              onChange={this.handleChange}
              value={this.state.firma.fakturace_zeme}
            />
          </div>
        </div>
      </div>
    );
  };

  deleteDorucovaciAdresa = async (adresaId, confirmed = false) => {
    if (!confirmed) {
      confirmAlert({
        title: "Smazat?",
        message: "Skutečně smazat tuto adresu?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteDorucovaciAdresa(adresaId, true),
          },
          {
            label: "No",
          },
        ],
      });
    } else {
      await http.post(config.apiEndpoint + "firma/smazatDodaciAdresu.php", {
        firma_id: this.state.aktualniFirma.firma_id,
        adresa_id: adresaId,
      });
      const dorucovaciAdresy = this.state.dorucovaciAdresy.filter(
        (e) => e.adresa_id !== adresaId
      );
      this.setState({ dorucovaciAdresy });
    }
  };

  upravDorucovaAdresa = async (input, adresaId) => {
    if (input.value) {
      const result = await http.post(
        config.apiEndpoint + "firma/upravitDodaciAdresu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          adresa_id: adresaId,
          attr: input.name,
          val: input.value,
        }
      );
      if (result.status === 200) {
        toast.success("Změna uložena");
      }
    } else {
      toast.error("Hodnota nesmí být prázdná");
    }
  };

  novaDorucovaciAdresa = async (data) => {
    if (data.nazev && data.ulice && data.mesto && data.psc && data.zeme) {
      const createData = {
        ...data,
        firma_id: this.state.aktualniFirma.firma_id,
      };
      const result = await http.post(
        config.apiEndpoint + "firma/novaDodaciAdresa.php",
        createData
      );
      const novaAdresa = [];
      novaAdresa[0] = result.data;
      const dorucovaciAdresy = [...novaAdresa, ...this.state.dorucovaciAdresy];
      this.setState({ dorucovaciAdresy });
    } else {
      toast.error("Všechny hodnoty jsou povinné");
    }
  };

  dokumenty = () => {
    if (this.state.dokumentyLoaded) {
      if (this.state.dokumenty.length) {
        return (
          <table className="margin-top-20">
            <thead>
              <tr>
                <th>Název</th>
                <th>Datum</th>
                <th>Velikost</th>
                <th>Typ souboru</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dokumenty.map((soubor) => (
                <tr key={soubor.firma_soubor_id}>
                  <td>
                    <a href={soubor.odkaz} target="_blank">
                      {soubor.nazev}
                    </a>
                  </td>
                  <td>{soubor.vlozil_datum}</td>
                  <td style={{ textAlign: "right" }}>{soubor.velikost}</td>
                  <td>{soubor.content_type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      } else {
        return (
          <div className="alert alert-danger alert-outline margin-top-20">
            Žádné dokumenty k zobrazení
          </div>
        );
      }
    } else {
      return <LoadMusic />;
    }
  };

  dorucovaciAdresy = () => {
    if (this.state.dorucovaciAdresyLoaded) {
      if (this.state.dorucovaciAdresy.length) {
        return (
          <div className="row margin-top-20" style={{ width: "100%" }}>
            <DodaciAdresa key="new" onCreate={this.novaDorucovaciAdresa} />
            {this.state.dorucovaciAdresy.map((e) => (
              <DodaciAdresa
                key={e.adresa_id}
                adresa={e}
                onDelete={this.deleteDorucovaciAdresa}
                onChange={this.upravDorucovaAdresa}
              />
            ))}
          </div>
        );
      } else {
        return (
          <React.Fragment>
            <div className="alert alert-danger alert-outline margin-top-20">
              Neevidujeme žádnou adresu
            </div>
            <div className="row" style={{ width: "100%" }}>
              <DodaciAdresa key="new" onCreate={this.novaDorucovaciAdresa} />
            </div>
          </React.Fragment>
        );
      }
    } else {
      return <LoadMusic />;
    }
  };

  novaOsoba = async (data) => {
    if (data["jmeno"] && data["prijmeni"] && data["email1"]) {
      const osoba = await http.post(
        config.apiEndpoint + "firma/novaOsoba.php",
        { firma_id: this.state.aktualniFirma.firma_id, ...data }
      );

      await this.nahrajOsoby();
      if (osoba.status === 200) {
        toast.success("Nová osoba uložena");
        return true;
      } else {
        toast.error("Došlo k chybě při ukládání.");
      }
    } else {
      toast.error("Celé jméno a e-mail jsou povinné údaje!");
    }
    return false;
  };

  upravaOsoby = async (osobaId, attr, val) => {
    if (val) {
      const result = await http.post(
        config.apiEndpoint + "firma/upravitOsobu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          osoba_id: osobaId,
          attr,
          val,
        }
      );
      if (result.status === 200) {
        toast.success("Změna uložena");
      }
      let osobaarr = [];
      const index = this.state.osoby.findIndex((e) => e.osoba_id === osobaId);
      osobaarr["titul_pred"] = this.state.osoby[index].titul_pred;
      osobaarr["jmeno"] = this.state.osoby[index].jmeno;
      osobaarr["prijmeni"] = this.state.osoby[index].prijmeni;
      osobaarr["titul_za"] = this.state.osoby[index].titul_za;
      osobaarr[attr] = val;
      const cele_jmeno =
        (osobaarr["titul_pred"] ? osobaarr["titul_pred"] + " " : "") +
        osobaarr["jmeno"] +
        " " +
        osobaarr["prijmeni"] +
        (osobaarr["titul_za"] ? ", " + osobaarr["titul_za"] : "");
      let osoby = [...this.state.osoby];
      osoby[index] = { ...osoby[index], cele_jmeno, [attr]: val };
      this.setState({ osoby });
    } else {
      toast.error("Hodnota nesmí být prázdná");
    }
  };

  deleteOsoby = async (osobaId, confirmed = false) => {
    if (!confirmed) {
      confirmAlert({
        title: "Smazat?",
        message: "Skutečně smazat tuto osobu?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteOsoby(osobaId, true),
          },
          {
            label: "No",
          },
        ],
      });
    } else {
      const osoby = this.state.osoby.filter(
        (osoba) => osoba.osoba_id !== osobaId
      );
      this.setState({ osoby });
      await http.post(config.apiEndpoint + "firma/smazatOsobu.php", {
        firma_id: this.state.aktualniFirma.firma_id,
        osoba_id: osobaId,
      });
    }
  };

  osoby = () => {
    if (this.state.osobyLoaded) {
      if (this.state.osoby.length) {
        return (
          <div className="row padding-top-20" style={{ width: "100%" }}>
            <Osoba key="nova_osoba" onCreate={this.novaOsoba} />
            {this.state.osoby.map((osoba) => (
              <Osoba
                key={osoba.osoba_id}
                osoba={osoba}
                onChange={this.upravaOsoby}
                onDelete={this.deleteOsoby}
              />
            ))}
          </div>
        );
      } else {
        return (
          <React.Fragment>
            <div className="alert alert-danger alert-outline margin-top-20">
              Neevidujeme u firmy žádné osoby
            </div>
            <div className="row" style={{ width: "100%" }}>
              <Osoba key="nova_osoba" onCreate={this.novaOsoba} />
            </div>
          </React.Fragment>
        );
      }
    } else {
      return <LoadMusic />;
    }
  };

  klienti = () => {
      return (
        <div className="padding-top-20">
          <input
              type="text"
              placeholder="Hledat klienty dle podle názvu, adresy, IČ, DIČ"
              className="form-control margin-bottom-20"
              onBlur={(input) => this.hledatKlienty(input.target.value)}
              onKeyUp={(input) => (input.key === 'Enter' || input.keyCode === 13) && this.hledatKlienty(input.target.value)}
          />


          {this.state.klientiLoaded ? (
              this.state.klienti && this.state.klienti.length ? (
              <table>
                <thead>
                <th>Klient</th>
                <th>Adresa</th>
                <th>IČ</th>
                <th>DIČ</th>
                <th></th>
                </thead>
                <tbody>
                {this.state.klienti.map((klient) => (
                    <tr key={klient.firma_id}>
                      <td><strong>{klient.nazev}</strong></td>
                      <td>
                        {klient.ulice}
                        <br />
                        {klient.psc} {klient.mesto}
                        <br />
                        {klient.zeme}
                      </td>
                      <td>
                        {klient.ico}
                      </td>
                      <td>
                        {klient.dic}
                      </td>
                      <td>
                        <NavLink to={"/obchod/firma/" + klient.firma_id} className="btn btn-primary">
                          Přiležitosti klienta
                        </NavLink>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
          ) : (
                <div className="alert alert-danger alert-outline margin-top-20">
                  Nejsou zadáni žádní klienti
                </div>)
          ) : (<LoadMusic />)
          }
        </div>
      );
  };

  nahrajDorucovaciAdresy = async () => {
    const dorucovaciAdresy = await http.post(
      config.apiEndpoint + "firma/dodaciAdresy.php",
      { firma_id: this.state.aktualniFirma.firma_id }
    );
    this.setState({
      dorucovaciAdresyLoaded: true,
      dorucovaciAdresy: dorucovaciAdresy.data,
    });
  };

  nahrajDokumenty = async () => {
    const dokumenty = await http.post(
      config.apiEndpoint + "firma/dokumenty.php",
      { firma_id: this.state.aktualniFirma.firma_id }
    );
    this.setState({
      dokumentyLoaded: true,
      dokumenty: dokumenty.data,
    });
  };

  nahrajOsoby = async () => {
    const osoby = await http.post(config.apiEndpoint + "firma/osoby.php", {
      firma_id: this.state.aktualniFirma.firma_id,
    });
    this.setState({
      osobyLoaded: true,
      osoby: osoby.data,
    });
  };

  nahrajKlienty = async () => {
    const klienti = await http.post(config.apiEndpoint + "firma/klienti.php", {
      firma_id: this.state.aktualniFirma.firma_id,
    });
    this.setState({
      klientiLoaded: true,
      klienti: klienti.data,
    });
  };

  hledatKlienty = async (hledat) => {
    this.setState({
      klientiLoaded: false,
      klienti: [],
    });
    const klienti = await http.post(config.apiEndpoint + "firma/klientiHledat.php", {
      firma_id: this.state.aktualniFirma.firma_id,
      hledat: hledat
    });
    this.setState({
      klientiLoaded: true,
      klienti: klienti.data,
    });
  };

  loadTab = (number) => {
    if (
      number === 1 &&
      !this.state.dorucovaciAdresyLoaded &&
      this.state.aktualniFirma.firma_id
    ) {
      this.nahrajDorucovaciAdresy();
    } else if (
      number === 2 &&
      !this.state.osobyLoaded &&
      this.state.aktualniFirma.firma_id
    ) {
      this.nahrajOsoby();
    } else if (
      number === 3 &&
      !this.state.klientiLoaded &&
      this.state.aktualniFirma.firma_id
    ) {
      this.nahrajKlienty();
    }
  };

  render() {
    const lang = auth.getLang();
    const user = auth.getCurrentUser();
    const bonus = false;/*
      user.aktualni_firma &&
      user.firmy &&
      user.firmy.find((f) => f.firma_id === user.aktualni_firma).agenda_id ===
        "cz";*/
    return (
      <React.Fragment>
        <NadpisStranky nadpis={lang.profil} />
        <div className="container">
          <Tabs
            className="awe-nav-responsive"
            onSelect={(n) => this.loadTab(n)}
          >
            <TabList className="awe-nav">
              <Tab>{lang.obecne_informace}</Tab>
              <Tab>{lang.dorucovaci_adresy}</Tab>
              <Tab>{lang.osoby}</Tab>
              <Tab>{lang.moji_klienti}</Tab>
              <Tab>{lang.moje_prihlasky}</Tab>
              <Tab>{lang.zmena_hesla}</Tab>
              {bonus && <Tab>Bonusprogram</Tab>}
            </TabList>
            <TabPanel>{this.obecneInformace()}</TabPanel>
            <TabPanel>{this.dorucovaciAdresy()}</TabPanel>
            <TabPanel>{this.osoby()}</TabPanel>
            <TabPanel>{this.klienti()}</TabPanel>
            <TabPanel>
              <MojePrihlasky />
            </TabPanel>
            <TabPanel>
              <ZmenaHesla />
            </TabPanel>
            {bonus && (
              <TabPanel>
                <Bonus hideHeader={true} />
              </TabPanel>
            )}
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}

export default Profil;
