import React, { Component } from "react";
import auth from "../services/authService";
import {getVeracoin, loadVeracoin} from "../services/veracoinService";
import {NavLink} from "react-router-dom";
import authService from "../services/authService";

class UserInfo extends Component {
  state = {
    user: {},
    aktualniFirma: {},
  };
  componentDidMount() {
    const user = auth.getCurrentUser();
    const aktualniFirma = auth.aktualniFirma();
    this.setState({ user, aktualniFirma });
  }

  handleZmenaFirmy = async ({ currentTarget: select }) => {
    localStorage.setItem("firma_id", select.value);
    await loadVeracoin();
    window.location.reload(true);
  };

  zmenaFirmy = () => {
    if (this.state.user.firmy) {
      if (this.state.user.firmy.length > 1) {
        return (
          <select
            onChange={this.handleZmenaFirmy}
            defaultValue={this.state.aktualniFirma.firma_id}
            style={{ maxWidth: "300px" }}
          >
            {this.state.user.firmy.map((e) => (
              <option key={e.firma_id} value={e.firma_id}>
                {e.nazev}
              </option>
            ))}
          </select>
        );
      } else {
        return <p>{this.state.aktualniFirma.nazev}</p>;
      }
    } else {
      return null;
    }
  };

  render() {
    const veracoin = -1;//getVeracoin();
    const lang = authService.getLang();

    return (
      <li className="menubar-account">
        <a href="#/profil" title="" className="awemenu-icon">
          <i className="icon icon-user-circle" />
          <span className="awe-hidden-text">{lang.odhlaseni}</span>
        </a>
        <ul className="submenu megamenu" style={{ width: "auto" }}>
          <li className="megamenu-item">
            <div className="container-fluid">
              <div className="header-account">
                <div className="header-account-username">
                  <h4>
                    <a href="#/profil">{this.state.user.jmeno}</a>
                  </h4>
                </div>
                {/*this.state.aktualniFirma.agenda_id == "cz" && veracoin != -1 && typeof veracoin !== "undefined" && (
                  <div className="menu-cart-total"
                       style={{
                         borderBottom: "none",
                         marginBotton: "0px",
                         paddingBottom: "0px",
                       }}>
                    <NavLink to="/bonus/detail">Dostupný kredit</NavLink>
                    <span className="price"><NavLink to="/bonus/meVeracoiny">v¢ {veracoin}</NavLink></span>
                  </div>
                )*/}
                <div
                  className="menu-cart-total"
                  style={{
                    borderBottom: "none",
                    marginBotton: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <span>{this.zmenaFirmy()}</span>
                </div>
                <div className="cart-action">
                  <a
                    href="#/profil"
                    title=""
                    className="btn btn-lg btn-dark btn-outline btn-block"
                  >
                    Profil
                  </a>
                  <a
                    href="#/obchod"
                    title=""
                    className="btn btn-lg btn-dark btn-outline btn-block"
                  >
                    {lang.obchodni_pripady}
                  </a>
                  {/*this.state.aktualniFirma.agenda_id == "cz" && veracoin != -1 && typeof veracoin !== "undefined" && (
                      <a
                          href="#/bonus"
                          title=""
                          className="btn btn-lg btn-dark btn-outline btn-block"
                      >
                        Bonus Program
                      </a>
                  )*/}
                  <a
                    href="#/logout"
                    title=""
                    className="btn btn-lg btn-primary btn-block"
                  >
                    {lang.odhlasit}
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    );
  }
}

export default UserInfo;
